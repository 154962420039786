<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="!isAdd"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    center
    lock-scroll
    top="5vh"
    width="70%"
  >
    <div :style="{zIndex: zIndex, position: isAdd ? 'sticky' : 'static'}" class="score-title">
      <div style="display:flex;">
        <div>
          <h4 style="display: inline-block">Score:</h4>
          <span style="margin-left: 7px">
            {{ score }}
          </span>
        </div>
        <div style="margin-left: 20px;">
          <h4 style="display: inline-block">Score Nature:</h4>
          <span style="margin-left: 7px">
            {{ scoreNature }}
          </span>
        </div>
      </div>
      <div v-if="isAdd">
        <el-button type="primary" @click="handleSubmit">Submit</el-button>
        <el-button @click="handleClose">Cancel</el-button>
      </div>
    </div>
    <h4 style="color:#409EFF;">1. Deducted Points</h4>
    <vxe-table
      ref="deductedPointsTable"
      :data="deductedPointsData"
      :loading="queryLoading"
      :max-height="400"
      :merge-cells="mergeDeductedCells"
      :row-class-name="isSeriousRow"
      :row-config="{ isCurrent:true, height: 100 }"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column field="ratingItem" title="Problem Type" width="180" />
      <vxe-column field="ratingSubitem" title="Problems" width="258" />
      <vxe-column v-if="isAdd" key="problem" title="Problem" width="100">
        <template #default="{row}">
          <el-radio v-model="row.scoreType" :label="+scoreTypeEnum['Problem']">
            {{ '-' + row.scoreProblem }}
          </el-radio>
        </template>
      </vxe-column>
      <vxe-column v-if="isAdd" key="suggestion" title="Suggestion" width="100">
        <template #default="{row}">
          <el-radio v-model="row.scoreType" :label="+scoreTypeEnum['Suggestion']">
            {{ '-' + row.scoreSuggestion }}
          </el-radio>
        </template>
      </vxe-column>
      <vxe-column v-if="isAdd" key="excellent" title="Excellent" width="100">
        <template #default="{row}">
          <el-radio v-model="row.scoreType" :label="+scoreTypeEnum['Excellent']">
            {{ ' ' }}
          </el-radio>
        </template>
      </vxe-column>
      <vxe-column v-else key="score" title="Score" width="140">
        <template #default="{row}">
          {{ getScoreRender(row.scoreType, row) }}
        </template>
      </vxe-column>
      <vxe-column align="left" header-align="center" min-width="300" title="Comment">
        <template #default="{row}">
          <v-clamp :max-height="100 * row.rowspan" autoresize>
            {{ filterImg(row.comment) }}
            <template #after>
              <el-button
                v-if="!isAdd && row.comment"
                type="text"
                @click="handleOpenCommentDialog(row, 'detail')"
              >
                View all
              </el-button>
              <el-button
                v-if="isAdd && row.comment"
                type="text"
                @click="handleOpenCommentDialog(row)"
              >
                Click to update
              </el-button>
            </template>
          </v-clamp>
          <div v-if="isAdd && !row.comment" style="text-align: center">
            <el-button type="text" @click="handleOpenCommentDialog(row)">
              Click to input
            </el-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <div class="vxe-header--column suggested-reply">Suggested Reply</div>
    <div class="suggested-reply-input">
      <el-input
        v-model="suggestedReply.comment"
        :autosize="{minRows:4}"
        :disabled="!isAdd"
        maxlength="2000"
        placeholder="Please Input"
        show-word-limit
        type="textarea"
      />
    </div>
    <h4 style="color:#409EFF;">2. Bonus</h4>
    <vxe-table
      ref="bonusTable"
      :checkbox-config="checkboxConfig"
      :data="bonusData"
      :loading="queryLoading"
      :max-height="400"
      :merge-cells="mergeBonusCells"
      :row-class-name="({row}) => (+row.scoreType === +scoreTypeEnum['Excellent'] ? 'serious' : '')"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column field="ratingItem" title="Item" width="200" />
      <vxe-column field="ratingSubitem" title="Sub Item" />
      <vxe-column :title="isAdd ? 'Select' : 'Score'" width="120">
        <template #default="{row}">
          <vxe-checkbox
            v-if="isAdd"
            key="checkbox"
            v-model="row.scoreType"
            :checked-value="+scoreTypeEnum['Excellent']"
            :unchecked-value="null"
          >
            {{ row.scoreExcellent ? `+${row.scoreExcellent}` : '' }}
          </vxe-checkbox>
          <span
            v-else
            key="scoreNature"
          >
            {{ +row.scorePoint === +row.scoreExcellent ? `Excellent(+${row.scorePoint})` : '/' }}
          </span>
        </template>
      </vxe-column>
    </vxe-table>
    <h4 style="color:#409EFF;">3. Best Case</h4>
    <vxe-table
      ref="best"
      :checkbox-config="{ checkMethod: () => scoreNature === 'Excellent' && isAdd, showHeader: false}"
      :data="bestData"
      :loading="queryLoading"
      :max-height="400"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column title="Select" type="checkbox" width="80" />
      <vxe-column title="Comment">
        <template v-slot="{row}">
          <el-input
            v-model="row.comment"
            :autosize="{minRows:2}"
            :disabled="!$refs.best.isCheckedByCheckboxRow(row) || !isAdd"
            maxlength="2000"
            placeholder="Please Input"
            show-word-limit
            type="textarea"
          />
        </template>
      </vxe-column>
    </vxe-table>
    <template v-if="!isAdd">
      <h4 style="color:#409EFF;">4. Escalation log</h4>
      <vxe-table
        ref="best"
        :data="logTableData"
        :loading="queryLoading"
        :scroll-x="{ enabled: false }"
        :scroll-y="{ enabled: false }"
        :show-overflow="false"
        align="center"
        border
        resizable
      >
        <vxe-column title="Type" field="processingType" min-width="100" />
        <vxe-column title="Processing result" field="processingResult" min-width="100" />
        <vxe-column title="Agent" field="agent" min-width="80" />
        <vxe-column title="Reason/Conments" field="reason" min-width="250" />
        <vxe-column title="Time" field="createTime" min-width="150" />
      </vxe-table>
    </template>
    <comment-dialog
      :initial-value="commentValue"
      :type="commentType"
      :visible.sync="commentDialogVisible"
      @input="handleInputComment"
    />
  </el-dialog>
</template>

<script>
import { getScoreDetail, updateScore, getEscalationLogById } from './apis/scoreDialog'
import { scoreTypeEnum, scoreTypeMap } from '../const'
import { isSeriousEnum, ruleTypeEnum } from '../score-rules/const'
import PopupManager from 'element-ui/src/utils/popup/popup-manager'
import VClamp from 'vue-clamp'
import CommentDialog from '../components/CommentDialog.vue'

export default {
  name: 'ScoreDialog',
  components: { VClamp, CommentDialog },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      commentType: 'edit',
      commentDialogVisible: false,
      zIndex: 1000,
      bestData: [{ ruleType: 2, comment: null }],
      suggestedReply: {
        comment: ''
      },
      isFirstOpenRichText: true,
      currentRow: null,
      isShowComment: false,
      commentValue: '',
      deductedPointsData: [],
      bonusData: [],
      queryLoading: false,
      mergeDeductedCells: [],
      mergeBonusCells: [],
      score: '',
      scoreNature: '',

      logTableData: []
    }
  },
  computed: {
    scoreTypeEnum() {
      return scoreTypeEnum
    },
    checkboxConfig() {
      return {
        showHeader: false
      }
    },
    isAdd() {
      return this.type === 'score'
    },
    title() {
      return this.isAdd ? 'Score' : 'QA Detail'
    },
    submitParams() {
      const { deductedPointsData, bonusData } = this
      const dData = deductedPointsData.map(e => {
        if (e.rowspan === 0) {
          // 说明是合并的单元格
          e.comment = deductedPointsData.find(item => item.ratingItem === e.ratingItem).comment
        }
        const scoreType = this.getScoreType(e.scoreType)
        e.scorePoint = +e[`score${scoreType}`]
        return e
      })
      dData.push({
        ruleType: 3,
        ...this.suggestedReply
      })
      const bData = bonusData.map(e => {
        e.scoreType = +e.scoreType === +scoreTypeEnum['Excellent'] ? +e.scoreType : null
        e.scorePoint = e.scoreType ? +e.scoreExcellent : 0
        return e
      })
      return dData.concat(bData).concat(this.bestData)
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.zIndex = PopupManager.nextZIndex()
        this.getScoreDetail()
        if (!this.isAdd) {
          this.getLogData()
        }
      }
    },
    deductedPointsData: {
      handler() {
        this.calcScore()
      },
      deep: true,
      immediate: true
    },
    bonusData: {
      handler() {
        this.calcScore()
      },
      deep: true,
      immediate: true
    },
    score(val) {
      if (val < 100) {
        this.$refs.best.clearCheckboxRow()
      }
    },
    scoreNature(val) {
      if (val !== 'Excellent') {
        this.bestData[0].comment = null
      }
    }
  },
  methods: {
    filterImg(val) {
      const arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"',
        'ldquo': '“',
        'mdash': '—',
        'rdquo': '”',
        'ndash': '—'
      }
      // 过滤 <img src=\"https:// 为 [图片]
      // 过滤 html 标签
      // 过滤 特殊符号
      return val
        .replace(/<img.*?(?:>|\/>)/gi, '[图片]')
        .replace(/<[^>]+>/g, '')
        .replace(/&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo|ndash);/ig, (all, t) => arrEntities[t])
    },
    handleInputComment(val) {
      this.currentRow.comment = val
      this.currentRow = null
    },
    handleOpenCommentDialog(row, type = 'edit') {
      this.commentType = type
      this.commentValue = row.comment
      this.currentRow = row
      this.commentDialogVisible = true
    },
    isSeriousRow({ row }) {
      if (this.isAdd) {
        return (+row.isSerious === +isSeriousEnum['是'] ? 'serious' : '')
      }
      return (+row.scoreType !== +scoreTypeEnum['Excellent'] ? 'serious' : '')
    },
    getHtmlStrCharLength(htmlStr) {
      return htmlStr
        .replace(/<[^>]*>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/^\s+/, '')
        .replace(/\s+$/, '').length
    },
    validate() {
      return this.submitParams.every(e => {
        if (+e.ruleType === 2) {
          // best case
          if (this.$refs.best.getCheckboxRecords().length && !this.bestData[0].comment) {
            this.$message.error('Please add comments')
            this.$refs.best.$el.scrollIntoView({
              behavior: 'smooth'
            })
            return false
          }
          return true
        }
        if (+e.ruleType === +ruleTypeEnum['减分项'] && (+e.scoreType === +scoreTypeEnum['Suggestion'] || +e.scoreType === +scoreTypeEnum['Problem'])) {
          if (!e.comment) {
            this.$message.error('Please add comments')
            this.$refs.deductedPointsTable.setCurrentRow(e)
            this.$refs.deductedPointsTable.$el.scrollIntoView({
              behavior: 'smooth'
            })
            return false
          }
        }
        if (+e.ruleType === +ruleTypeEnum['减分项']) {
          if (this.getHtmlStrCharLength(e.comment) > 2000) {
            this.$message.error('Cannot exceed 2000 characters')
            this.$refs.deductedPointsTable.setCurrentRow(e)
            this.$refs.deductedPointsTable.$el.scrollIntoView({
              behavior: 'smooth'
            })
            return false
          }
        }
        return true
      })
    },
    calcScore() {
      let score = this.deductedPointsData.reduce((total, item) => {
        const scoreType = this.getScoreType(item.scoreType)
        if (scoreType) {
          return total - (item[`score${scoreType}`] || 0)
        }
        return total
      }, 100)
      score = this.bonusData.reduce((total, item) => {
        if (+item.scoreType === +scoreTypeEnum['Excellent']) {
          return total + (item.scoreExcellent || 0)
        }
        return total
      }, score)
      this.score = score
      this.scoreNature = this.getScoreNature(score)
    },
    getScoreRender(type, row) {
      const scoreType = this.getScoreType(type)
      if (scoreType === 'Problem' || scoreType === 'Suggestion') {
        return `${scoreType}(-${row['score' + scoreType]})`
      }
      return scoreType
    },
    getScoreType(type) {
      return scoreTypeMap[type]
    },
    getScoreNature(score) {
      // IF(总分>=85,IF(总分>=97,"Excellent","Qualified"),"Unqualified")
      return score >= 85 ? (score >= 97 ? 'Excellent' : 'Qualified') : 'Unqualified'
    },
    async getLogData() {
      this.queryLoading = true
      const { datas } = await getEscalationLogById(this.row.id).finally(() => {
        this.queryLoading = false
      })
      this.escalationLogData = datas
    },
    async getScoreDetail() {
      const { id } = this.row
      if (!id) return
      this.queryLoading = true
      const { datas } = await getScoreDetail(id).finally(() => {
        this.queryLoading = false
      })
      let list = (datas || []).filter(e => +e.ruleType === +ruleTypeEnum['减分项']).map(e => ({
        ...e,
        comment: e.comment ?? '',
        scoreType: e.scoreType ?? +scoreTypeEnum['Excellent']
      }))
      this.deductedPointsData = this.getMergeAttr(list)
      list = (datas || []).filter(e => +e.ruleType === +ruleTypeEnum['加分项']).map(e => ({
        ...e,
        scoreType: e.scoreType ?? null
      }))
      this.bonusData = this.getMergeAttr(list)
      this.suggestedReply = (datas || []).find(e => +e.ruleType === 3) || {}
      const bestData = (datas || []).filter(e => +e.ruleType === 2).map(e => ({
        ...e,
        comment: e.comment ?? null
      }))
      if (bestData.length) {
        this.bestData = bestData
        if (bestData[0].comment) {
          this.$nextTick(() => {
            // watch 分数小于100会清空选项，因此延迟一下
            this.$refs.best.selection.push(bestData[0])
          })
          // await this.$refs.best.setCheckboxRow(bestData[0], true)
        }
      }
      this.mergeDeductedCells = this.handleMergeCells(this.deductedPointsData, 'deductedPointsData')
      this.mergeBonusCells = this.handleMergeCells(this.bonusData, 'bonusData')
    },
    getMergeAttr(data) {
      let list = []
      const set = new Set()
      data.map(e => {
        // 找到相同的ratingItem
        const { ratingItem } = e
        if (set.has(ratingItem)) return
        const sameRatingItem = data.filter(item => item.ratingItem === ratingItem)
        const len = sameRatingItem.length
        list = list.concat(sameRatingItem.map((e, i) => ({
          ...e,
          rowspan: i === 0 ? len : 0,
          colspan: i === 0 ? 1 : 0
        })))
        set.add(ratingItem)
      })
      return list
    },
    handleMergeCells(data, type) {
      const mergeCells = []
      let mergeFields = []
      if (this.isAdd) {
        mergeFields = type === 'deductedPointsData' ? ['ratingItem', '', '', '', '', 'comment', 'operate'] : ['ratingItem']
      } else {
        mergeFields = type === 'deductedPointsData' ? ['ratingItem', '', '', 'comment'] : ['ratingItem']
      }
      data.forEach((item, row) => {
        if (item.rowspan > 1) {
          mergeFields.forEach((field, col) => {
            if (field) {
              mergeCells.push({
                row,
                col,
                rowspan: item.rowspan,
                colspan: item.colspan
              })
            }
          })
        }
      })
      return mergeCells
    },
    handleClose() {
      this.bestData = [{ ruleType: 2, comment: null }]
      this.suggestedReply = {
        comment: ''
      }
      this.currentRow = null
      this.isShowComment = false
      this.commentValue = ''
      this.deductedPointsData = []
      this.bonusData = []
      this.mergeDeductedCells = []
      this.mergeBonusCells = []
      this.score = ''
      this.scoreNature = ''
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      if (!this.isAdd) {
        this.handleClose()
        return
      }
      const valid = this.validate()
      if (!valid) return
      this.$confirm('Are you sure to submit?', 'Prompt', {
        closeOnClickModal: false,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { msg } = await updateScore(this.submitParams).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            this.$message.success(msg)
            this.$emit('refresh')
            this.handleClose()
            done()
          } else {
            done()
          }
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

.score-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 80px;
  background-color: #FFF;
  padding: 5px 0;
}

.suggested-reply {
  height: 48px;
  text-align: center;
  line-height: 48px;
  color: #606266;
  border: 1px solid #e8eaec;
  border-top: 0;
  border-bottom: 0;
  background-color: rgb(248, 248, 249);
}

.suggested-reply-input {
  padding: 10px;
  border: 1px solid #e8eaec;
}

</style>

<style>
.serious {
  color: red;
}
</style>
