import request from '@/utils/request'

export function getScoreDetail(id) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityDetailById/${id}`
  })
}

export function updateScore(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/updateQualityDetailById`,
    method: 'post',
    data
  })
}

export function getEscalationLogById(id) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getEscalationLogById/${id}`,
    method: 'get'
  })
}
