<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    append-to-body
    center
    destroy-on-close
    title="Comment"
    top="5vh"
    width="748px"
  >
    <rich-text v-if="visible" ref="comment" :disabled="isDetail" :value="commentContent" @input="handleInput">
      <template #button>
        <span style="margin-left: 5px">
          Characters count
          {{ getCharactersCount() }}
        </span>
      </template>
    </rich-text>
    <template v-if="!isDetail" #footer>
      <el-button @click="handleClose">Cancel</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">Submit</el-button>
    </template>
  </el-dialog>
</template>

<script>
import RichText from '@/components/RichText/index.vue'

export default {
  name: 'CommentDialog',
  components: {
    RichText
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'edit'
    }
  },
  data() {
    return {
      commentContent: '',
      submitLoading: false
    }
  },
  computed: {
    isDetail() {
      return this.type !== 'edit'
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.commentContent = this.initialValue
      }
    }
  },
  methods: {
    getCharactersCount() {
      return this.$refs.comment?.getHtmlStrCharLength(this.commentContent) || 0
    },
    handleInput(val) {
      this.commentContent = val
    },
    handleClose() {
      this.commentContent = ''
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      this.$emit('input', this.commentContent)
      this.handleClose()
    }
  }
}
</script>

<style scoped>

</style>
