import request from '@/utils/request'

export function getQualityInspectionList(params, signal) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityByPage`,
    params,
    signal
  })
}

export function getQualityInspectionRulesLatestFile() {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityRulesLatestFiles`
  })
}

export function getQualityInspectionRulesFileList() {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityRulesFiles`
  })
}

export function getCustomerServiceList() {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityAgent`
  })
}

export function updateAgent(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/updateQualityResponsibleAgentById`,
    method: 'post',
    data
  })
}
