var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"before-close":_vm.handleClose,"close-on-click-modal":!_vm.isAdd,"close-on-press-escape":false,"title":_vm.title,"visible":_vm.visible,"center":"","lock-scroll":"","top":"5vh","width":"70%"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"score-title",style:({zIndex: _vm.zIndex, position: _vm.isAdd ? 'sticky' : 'static'})},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',[_c('h4',{staticStyle:{"display":"inline-block"}},[_vm._v("Score:")]),_c('span',{staticStyle:{"margin-left":"7px"}},[_vm._v(" "+_vm._s(_vm.score)+" ")])]),_c('div',{staticStyle:{"margin-left":"20px"}},[_c('h4',{staticStyle:{"display":"inline-block"}},[_vm._v("Score Nature:")]),_c('span',{staticStyle:{"margin-left":"7px"}},[_vm._v(" "+_vm._s(_vm.scoreNature)+" ")])])]),(_vm.isAdd)?_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("Submit")]),_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("Cancel")])],1):_vm._e()]),_c('h4',{staticStyle:{"color":"#409EFF"}},[_vm._v("1. Deducted Points")]),_c('vxe-table',{ref:"deductedPointsTable",attrs:{"data":_vm.deductedPointsData,"loading":_vm.queryLoading,"max-height":400,"merge-cells":_vm.mergeDeductedCells,"row-class-name":_vm.isSeriousRow,"row-config":{ isCurrent:true, height: 100 },"scroll-x":{ enabled: false },"scroll-y":{ enabled: false },"show-overflow":false,"align":"center","border":"","resizable":""}},[_c('vxe-column',{attrs:{"field":"ratingItem","title":"Problem Type","width":"180"}}),_c('vxe-column',{attrs:{"field":"ratingSubitem","title":"Problems","width":"258"}}),(_vm.isAdd)?_c('vxe-column',{key:"problem",attrs:{"title":"Problem","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-radio',{attrs:{"label":+_vm.scoreTypeEnum['Problem']},model:{value:(row.scoreType),callback:function ($$v) {_vm.$set(row, "scoreType", $$v)},expression:"row.scoreType"}},[_vm._v(" "+_vm._s('-' + row.scoreProblem)+" ")])]}}],null,false,1814043675)}):_vm._e(),(_vm.isAdd)?_c('vxe-column',{key:"suggestion",attrs:{"title":"Suggestion","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-radio',{attrs:{"label":+_vm.scoreTypeEnum['Suggestion']},model:{value:(row.scoreType),callback:function ($$v) {_vm.$set(row, "scoreType", $$v)},expression:"row.scoreType"}},[_vm._v(" "+_vm._s('-' + row.scoreSuggestion)+" ")])]}}],null,false,1299676443)}):_vm._e(),(_vm.isAdd)?_c('vxe-column',{key:"excellent",attrs:{"title":"Excellent","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-radio',{attrs:{"label":+_vm.scoreTypeEnum['Excellent']},model:{value:(row.scoreType),callback:function ($$v) {_vm.$set(row, "scoreType", $$v)},expression:"row.scoreType"}},[_vm._v(" "+_vm._s(' ')+" ")])]}}],null,false,980901845)}):_c('vxe-column',{key:"score",attrs:{"title":"Score","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getScoreRender(row.scoreType, row))+" ")]}}])}),_c('vxe-column',{attrs:{"align":"left","header-align":"center","min-width":"300","title":"Comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('v-clamp',{attrs:{"max-height":100 * row.rowspan,"autoresize":""},scopedSlots:_vm._u([{key:"after",fn:function(){return [(!_vm.isAdd && row.comment)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpenCommentDialog(row, 'detail')}}},[_vm._v(" View all ")]):_vm._e(),(_vm.isAdd && row.comment)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpenCommentDialog(row)}}},[_vm._v(" Click to update ")]):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.filterImg(row.comment))+" ")]),(_vm.isAdd && !row.comment)?_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleOpenCommentDialog(row)}}},[_vm._v(" Click to input ")])],1):_vm._e()]}}])})],1),_c('div',{staticClass:"vxe-header--column suggested-reply"},[_vm._v("Suggested Reply")]),_c('div',{staticClass:"suggested-reply-input"},[_c('el-input',{attrs:{"autosize":{minRows:4},"disabled":!_vm.isAdd,"maxlength":"2000","placeholder":"Please Input","show-word-limit":"","type":"textarea"},model:{value:(_vm.suggestedReply.comment),callback:function ($$v) {_vm.$set(_vm.suggestedReply, "comment", $$v)},expression:"suggestedReply.comment"}})],1),_c('h4',{staticStyle:{"color":"#409EFF"}},[_vm._v("2. Bonus")]),_c('vxe-table',{ref:"bonusTable",attrs:{"checkbox-config":_vm.checkboxConfig,"data":_vm.bonusData,"loading":_vm.queryLoading,"max-height":400,"merge-cells":_vm.mergeBonusCells,"row-class-name":function (ref) {
	var row = ref.row;

	return (+row.scoreType === +_vm.scoreTypeEnum['Excellent'] ? 'serious' : '');
},"scroll-x":{ enabled: false },"scroll-y":{ enabled: false },"show-overflow":false,"align":"center","border":"","resizable":""}},[_c('vxe-column',{attrs:{"field":"ratingItem","title":"Item","width":"200"}}),_c('vxe-column',{attrs:{"field":"ratingSubitem","title":"Sub Item"}}),_c('vxe-column',{attrs:{"title":_vm.isAdd ? 'Select' : 'Score',"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isAdd)?_c('vxe-checkbox',{key:"checkbox",attrs:{"checked-value":+_vm.scoreTypeEnum['Excellent'],"unchecked-value":null},model:{value:(row.scoreType),callback:function ($$v) {_vm.$set(row, "scoreType", $$v)},expression:"row.scoreType"}},[_vm._v(" "+_vm._s(row.scoreExcellent ? ("+" + (row.scoreExcellent)) : '')+" ")]):_c('span',{key:"scoreNature"},[_vm._v(" "+_vm._s(+row.scorePoint === +row.scoreExcellent ? ("Excellent(+" + (row.scorePoint) + ")") : '/')+" ")])]}}])})],1),_c('h4',{staticStyle:{"color":"#409EFF"}},[_vm._v("3. Best Case")]),_c('vxe-table',{ref:"best",attrs:{"checkbox-config":{ checkMethod: function () { return _vm.scoreNature === 'Excellent' && _vm.isAdd; }, showHeader: false},"data":_vm.bestData,"loading":_vm.queryLoading,"max-height":400,"scroll-x":{ enabled: false },"scroll-y":{ enabled: false },"show-overflow":false,"align":"center","border":"","resizable":""}},[_c('vxe-column',{attrs:{"title":"Select","type":"checkbox","width":"80"}}),_c('vxe-column',{attrs:{"title":"Comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"autosize":{minRows:2},"disabled":!_vm.$refs.best.isCheckedByCheckboxRow(row) || !_vm.isAdd,"maxlength":"2000","placeholder":"Please Input","show-word-limit":"","type":"textarea"},model:{value:(row.comment),callback:function ($$v) {_vm.$set(row, "comment", $$v)},expression:"row.comment"}})]}}])})],1),(!_vm.isAdd)?[_c('h4',{staticStyle:{"color":"#409EFF"}},[_vm._v("4. Escalation log")]),_c('vxe-table',{ref:"best",attrs:{"data":_vm.logTableData,"loading":_vm.queryLoading,"scroll-x":{ enabled: false },"scroll-y":{ enabled: false },"show-overflow":false,"align":"center","border":"","resizable":""}},[_c('vxe-column',{attrs:{"title":"Type","field":"processingType","min-width":"100"}}),_c('vxe-column',{attrs:{"title":"Processing result","field":"processingResult","min-width":"100"}}),_c('vxe-column',{attrs:{"title":"Agent","field":"agent","min-width":"80"}}),_c('vxe-column',{attrs:{"title":"Reason/Conments","field":"reason","min-width":"250"}}),_c('vxe-column',{attrs:{"title":"Time","field":"createTime","min-width":"150"}})],1)]:_vm._e(),_c('comment-dialog',{attrs:{"initial-value":_vm.commentValue,"type":_vm.commentType,"visible":_vm.commentDialogVisible},on:{"update:visible":function($event){_vm.commentDialogVisible=$event},"input":_vm.handleInputComment}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }